// Реализация модального окна
import GraphModal from 'graph-modal';
const modal = new GraphModal();

import { validateForms } from '../functions/validate-forms';

const rules1 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Минимально 3 символа!'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm = () => {
  modal.open('thanks');
  // modal.close('callme');
  // modal.close('lead');

  // console.log('Произошла отправка, тут можно писать любые действия');
};

validateForms('.form', rules1, afterForm);

// ФОРМА 2
const rules2 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Минимально 3 символа!'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm2 = () => {
  modal.close('callme');
  modal.open('thanks');

  // console.log('Произошла отправка, тут можно писать любые действия');
};

validateForms('.form-2', rules2, afterForm2);

// ФОРМА 2
const rules3 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Минимально 3 символа!'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];

const afterForm3 = () => {
  modal.close('lead');
  modal.open('thanks');

  // console.log('Произошла отправка, тут можно писать любые действия');
};

validateForms('.form-3', rules3, afterForm3);
